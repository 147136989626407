import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-scroll";
import cn from "classnames";
import Icon from "@ui/icon";
import { MenuType } from "@utils/types";
import Anchor from "../ui/anchor";

const MainMenu = ({ menus, className, navId }) => {
    return (
        <nav
            id={navId}
            className={cn("mainmenu-nav navbar-example2", className)}
        >
            <ul className="primary-menu nav nav-pills">
                {menus.map(({ id, text, path, icon }) => (
                    <li key={id} className="nav-item">
                        <Anchor path={path}>{text}</Anchor>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

MainMenu.propTypes = {
    menus: PropTypes.arrayOf(PropTypes.shape(MenuType)).isRequired,
    navId: PropTypes.string,
    className: PropTypes.string,
};

MainMenu.defaultProps = {
    navId: "sideNav",
};

export default MainMenu;
